/*Produits*/
.five ,.fv1{
    display: flex;
    justify-content: space-between;
    margin-top:-1em;
}
.five h1{
    margin: 7em 0 0 -12em;
}

.five h2{
    margin: 0.3em 4em;
}

.five .lien{
   width: 15em;
   margin-left: 3em;
   margin-top: 6em;
    text-transform: uppercase;
}

.five2{
    display: flex;
    width: 95%;
    justify-content: space-between;
    margin: 4em auto;
    background-color: #1a1a1a;
}

.nosproduits{
    margin-left: -8em;
}


/*card1*/
.fv1{
    background-image: url(../../images/rhumback.jpg);
  width: 33em;
}

.card1img{
    width: 12em;
    height: 10em;
    float: left;
    margin-top: 10em;
    margin-left:4em;
}
.btl1{
    margin-top: 2em;
    width: 12em;
    height: 35em;
}

.bouton{
    font-family: "Moonshine";
    background-color: #1a1a1a;
    color: #fff;
    letter-spacing: 4px;
    font-size: 0.8rem;
    text-transform: uppercase;
    text-decoration: none;
    height: 3.5em;
    padding: 1em 1.2em 1em;
  position: relative;
  top:30em;
  left: -12em;
}
.fivecard2{
    display: flex;
    flex-direction: column;
  width: 48%;
    margin-top: -1em;
}

.card21{
    background-color: #000;z-index: 7;position: relative;
}

/*card2*/
.fivecard20{
    background-image: url("../../images/Five/card2back.jpg");
    width: 35em;
    height: 22em;
}
.card2img{
    width: 7em;
    height: 5em;
    float: left;
    margin:1em 2em;
}

.fivecard20 p,.fivecard21 p{
    margin-top: 6.5em;
    margin-left: 2em;
    text-align: left;
    width: 25em;
}
.card2btn{
  margin: 0em 11em 0 0em;
  font-family: "Moonshine";
  background-color: #1a1a1a;
  color: #fff;
  letter-spacing: 4px;
  font-size: 0.8rem;
  text-transform: uppercase;
  text-decoration: none;
  height: 3.5em;
  padding: 1em 1.2em 1em;
}
.btl2{
    width: 13em;
    height: 18em;
    margin-top: -12em;
}

/*card3*/

.fivecard21{
    background-image: url(../../images/Five/card3back.png);
    height: 22em;
    width: 39em;
    }
    
.card3img{
    width: 16em;
    height: 6em;
    float: left;
    margin:1em 2em;
}

.card3btn{
 margin: 0em 13em 0 0em;
  font-family: "Moonshine";
  background-color: #1a1a1a;
  color: #fff;
  letter-spacing: 4px;
  font-size: 0.8rem;
  text-transform: uppercase;
  text-decoration: none;
  height: 3.5em;
  padding: 1em 1.2em 1em;
 }
 .btl3{
     width: 13em;
     height: 18em;
     margin-top: -18em;
     margin-right: -26em;
 }
 
 /*card4*/
 .section4{
   background-image: url(../../images/Five/card4back.jpg);
    width: 94%;
    margin: 2em auto;
    height: 12em;
 }
 .lien1{
    letter-spacing: 2px;
    font-size: 0.6rem;
 }
 .lien2{
    font-weight: 700;
    font-size: 1.2rem;
 }
 .card4Lien{
    color: #000;
    letter-spacing: 4px;  
    line-height: 1.5;
    text-decoration: none;
    text-transform: uppercase;
    text-align: right;
   
 }
.fivep{
    width: 50%;
}
 /*section5*/
 .section5{
    background-image: url(../../images/Five/maki.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 38%;
    width: 70%;
    margin: 0em auto;
    height: 60em;
 }

 .sect5card0{
    background-image: url(../../images/Five/card5back.jpg);
    background-size: cover;
    margin-left: -5em;
    width: 65%;
    height: 29em;
 }

 .section5 p{
    text-align: left;
    margin: 2em auto;
    line-height: 2.5em;
 }

 .section5 h1{
    position: relative;
    top: 2em;
    margin: 4.8em;
    text-align: left;
 }
 .section5 h2{
text-transform: uppercase;
text-align: left;
margin-left: 2.5em;
color: #fff;
font-family: "Scheherazade", serif;
font-size: 1.8rem;
line-height: 1.5;
letter-spacing: 4px;
 }

.safir{
width: 10em;
height: 10em;
margin-top: -10em;
margin-left: 27.5em;
}

.section5 .lien{
    margin-top: -10em;
    margin-right: -35em;
    float: right;
    letter-spacing: 0.3em;
    font-size: 12px;
}
.histoire{
    width: 35em;
    height: auto;
    margin: -2.5em 35em;
}

.text{
    color: #fff;
    font-style: italic;
    font-size: 1.3rem;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.8;
    text-align: left;
    margin: -12em 0 0 2em;
    width: 80%;
}
.ligne{
    background-color: chocolate;
    margin-left:2em;
    margin-top: 1.5em;
    width: 15em;
    height: 0.1em;
}
