.fourth{

    background-color: #1a1a1a;
    height: 25em;
    margin: 0 auto;
    width: 102%;
    display: flex;
    justify-content: space-between;
}
.fourthcard{
    margin-top: 2em;
    margin-right: 2em;
}
.fourthp{
    width: 70%;
    text-align: left;
    margin: 1.5em 4em ;
}

.f1{
    background-image: url(../../images/valeur2.jpg);
    background-size: cover;

}
.f2{
    background-image: url(../../images/valeur3.jpg);
    background-size: cover;
}

.fourth h1{
    margin: 2em 0 1.5em -18em;
}

.fourth h2,.five h2{
   
    font-family: "Scheherazade", serif;
    color: #fff;
    margin-left:1.4em ;
    font-size: 2.5rem;
    line-height: 1;
    letter-spacing: 8px;
    text-transform: uppercase;
    width: 20%;
}
