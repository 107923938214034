.App {
  text-align: center;
  background-color: #1a1a1a;
  min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
body{
  overflow-x: hidden;
}

