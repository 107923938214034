.Csection1{
    width: 85%;
    margin: 0 auto;
  
}
.Csection1 h1{
    color: #fff;
    font-family: "Scheherazade", serif;
    font-size: 2rem;
    line-height: 1;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-align: center;
/*media screen  margin: 0 20% 0 20%;*/
   
}
.Csection2 h2{
    color: #ff7116;
    font-size: 1rem;
    font-family: "Scheherazade";
    line-height: 1;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: center;
}
.Csection2{
display: flex;
justify-content: space-between;
margin-top: 5em;
}
.sect1{
    width: 55%;
    background-color: #101010;
    padding: 5em 1em 0em;
}
.sect2{
    width: 42%;
    background-color: #1a1a1a;
}
.sect21,.sect22{
    background-color: rgb(245, 243, 243);
    margin-bottom: 3em;
}

.divinput{
    width: 90%;
    margin: 2em auto;
    text-align: left;
}
label {
    display: inline-block;
    color: #fff;
}
.etoile{
    color: #d11010;
}
input ,textarea {
    background: #1a1a1a;
    color: #fff;
    border: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

input[type="submit"] {
    cursor: pointer;
    width: 30%;
    background-color: #ff7116;
    padding: 5px  ;
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    float: left;
}
input[type="text"]:focus{
    border-color:#1a1a1a;
}
.accepter{
    display: flex;
}
.accepter2{
    display: flex;
    margin: 2em 0;
}

.accepter label {
    display: inline-block;
    margin-left: 1em;
}

.divinput2{
    width: 90%;
    margin: 0em auto ;
    padding: 20px;
    text-align: left;
}
.divinput2 h1{
    color: #101010;
    font-size: 120%;
    margin-bottom: 0.5em;
}
.sect2text{
    margin-left: 1em;
    font-size: 0.9em;
}
.icn{
    width: 2em;
    height: 2em;
}
body.modal-open {
    overflow-y: hidden;
}

.message-modal{
    margin: 3em auto;
}

.message-modal h2{
    margin-top: 1.5em ;
    color: #ff7116;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-size: 1.5em;
}
.modal-content-body-m{
    background: #0f0f0f;
    width: 500px;
    height: 250px;
    position: fixed;
    z-index: 100;
   margin-top: -2em;
   margin-left: 23em;
   box-shadow: 5px 5px 5px #101010;
}

@media screen and (max-width:800px) {
    .Csection2 {
        display: block;
    }
    .sect1,.sect2 {
        width: 100%;
    }
    .sect1 {
        padding-bottom: 2em;
        margin-bottom: 2em;
    }
    input[type="submit"]{
        margin-top: -2em;
    }
}