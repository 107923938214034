
/*Third*/
.third{
    background-color: #141414;
    height: 20em;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.thirdtext
{
    display: flex;
    width: 25%;
    height: 8em;
    margin : 2.6em 2.5em 0em ;
}

.thirdtext h1{
    font-family: "Moonshine";
    color: #ffffff8c;
    letter-spacing: 6px;
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 0;
    line-height: 2em;
}

.thirdtext h2{
    font-family: "Scheherazade", serif;
    color: #ffab3c;
    font-size: 5rem;
}
.h1{
    margin-left: -2.1em;
}

.h12{
    text-align: left;
    margin-left: 1em;
}

.thirdtext p{
    font-size: 0.7rem;
    letter-spacing: 3px;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.45);
    line-height: 2em;
}
.ligne2{
    width: 4em;
    height: 0.05em;
    background-color: #ffab3c;
    margin-right: 0.4em;
    margin-top: -0.2em;
}
.div-lien{
    display: flex;
    width: 15em;
    float:right;
    margin-top: -1.5em;
    margin-bottom: 2em;
    z-index: 3;
    position: relative;
}
.lien{
    margin-top: -0.8em ;
   float: left;
   font-family: "Moonshine";
    color: #ffffff;
    text-decoration: none; 
}
.btl2{
    width: 11em;   
    margin-right: -11em;
}
.btl3{
    margin-right: -22em;
}
/*Section4*/
card4Lien{
    top: 2em;
    right: -20em;
}